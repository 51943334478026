'use client'
import clsx from 'clsx'
import { useRouter } from '@i18n/navigation'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Navigation } from 'swiper/modules'
import shuffle from 'lodash/shuffle'
import qs from 'qs'
import { ptMap, pbMap } from '@lib/token-maps'
import type { TypeFadPromoBlockFields } from '@lib/generated-types'
import ProviderPromoCard from '@components/provider-promo-card'
import { type RouteState, getSearchDefaultClient, getSearchRouter } from '@lib/search'
import { useGeocode, geocode } from '@hooks/useGeocode'
import type { GeoData } from '@lib/utilities/location-utilities'
import { menuItemMapper } from '@lib/mappers/menu-item-mapper'
import { Button, Container, Icon, Typography } from '@shc/ui'
import { useAppConfig } from '@components/client-wrapper'
import useAnalytics from '@hooks/use-analytics'
import { AnalyticsLink } from '@components/analytics'
import NextLink from 'next/link'
import slugify from '@sindresorhus/slugify'

const MAX_HITS_TO_SHOW = 4

const FadPromoBlock = ({
  sectionLink,
  fadTitle,
  fadDescription,
  tagTitle,
  tags,
  queryString,
  buttonLabel,
  showAppointments,
  blockAlignment,
  backgroundColor,
  paddingTop,
  paddingBottom,
}: TypeFadPromoBlockFields) => {
  const { trackSnowplow } = useAnalytics()
  const appConfig = useAppConfig()
  const algoliaKey = appConfig.configs.algolia.algoliaKey
  let indexName = appConfig.configs.algolia.providersIndexName
  const links = tags?.map(menuItemMapper)
  const { isLoaded: isGoogleApiLoaded } = useGeocode()
  const [location, setLocation] = useState<GeoData[] | null>()
  const [providerHits, setProviderHits] = useState<any[] | null>()
  const [providerCount, setProviderCount] = useState<number>(0)
  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null)
  const router = useRouter()

  //set variables from querystring
  const queryParams = new URLSearchParams(queryString)
  const searchLocation = queryParams.get('l') ?? ''
  const sortBy = queryParams.get('s') ?? ''
  const query = queryParams.get('q') ?? ''

  //deletes version, location, sort, and query params from the url
  //the rest of queryparams if any, are facets
  queryParams.delete('v')
  queryParams.delete('l')
  queryParams.delete('s')
  queryParams.delete('q')

  //geolocation
  useEffect(() => {
    const fetchData = async () => {
      if (isGoogleApiLoaded && searchLocation) {
        const results = await geocode(searchLocation)
        if (results) {
          setLocation(results)
        }
      }
    }
    fetchData()
  }, [isGoogleApiLoaded, searchLocation])

  //setting the correct index if sort by is used
  if (sortBy === 'first_available') {
    indexName = `${indexName}_first_available`
  } else if (sortBy === 'name') {
    indexName = `${indexName}_name`
  }

  const searchClient = getSearchDefaultClient(algoliaKey)
  const providersIndex = searchClient.initIndex(indexName)
  const routeState: RouteState = qs.parse(queryString.slice(1), { comma: true })
  const facetState = getSearchRouter(indexName, router).stateMapping?.routeToState(routeState)

  //formats the facets  - an example of the result is this:
  // [['medical_groups_facet:Sharp Rees-Stealy'],
  // ['specialties_facet:Cardiology > Cardiac electrophysiology', 'specialties_facet:Cardiology > Cardiovascular disease']]
  const refinementList = facetState?.[indexName]?.refinementList
  delete refinementList?.l

  const formattedFacets = refinementList
    ? Object.entries(refinementList ?? {}).map(([facet, values]) =>
        values.map((value) => `${facet}: ${value}`)
      )
    : []

  const moreProvidersLabel =
    buttonLabel && buttonLabel.length > 0 ? buttonLabel : 'View more doctors'
  const moreProvidersLink = `/search/doctors${queryString}`

  //searches algolia index with
  //appropriate filters and location
  useEffect(() => {
    //return if no location and location is defined
    if (searchLocation.length && !location?.length) {
      return
    }

    providersIndex
      .search(`${query}`, {
        hitsPerPage: 100,
        facetFilters: JSON.stringify(formattedFacets),
        aroundLatLng: location?.length ? `${location[0]?.lat}, ${location[0]?.lng}` : undefined,
        aroundLatLngViaIP: !location?.length,
        filters: 'has_photo',
      })
      .then(({ hits }) => {
        setProviderCount(hits.length)
        const shuffledHits = shuffle(hits)
        setProviderHits(
          shuffledHits.length > MAX_HITS_TO_SHOW
            ? shuffledHits.slice(0, MAX_HITS_TO_SHOW)
            : shuffledHits
        )
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //variables for the alignments and the background color
  const justifyAlignment = blockAlignment === 'left' ? 'justify-start' : 'justify-center'
  const itemsAlignment = blockAlignment === 'left' ? 'items-start' : 'items-center'
  const bgColor = backgroundColor == 'gray-75' ? 'bg-gray-75' : 'bg-primary-50'

  return (
    <section
      id={sectionLink ? slugify(sectionLink) : undefined}
      data-testid="fad-promo-block"
      className={clsx('bg-clip-content', `${bgColor}`, ptMap[paddingTop!], pbMap[paddingBottom!])}>
      <Container className="py-12 lg:py-15">
        <div className={`flex flex-col ${itemsAlignment}`}>
          {fadTitle?.length && (
            <Typography variant="h2" className={fadDescription?.length ? 'mb-6' : 'mb-8'}>
              {fadTitle}
            </Typography>
          )}
          {fadDescription?.length && (
            <Typography variant="body" className={tagTitle?.length ? 'mb-6' : 'mb-8'}>
              {fadDescription}
            </Typography>
          )}
          {tagTitle?.length && (
            <Typography variant="body-semibold" className="mb-4">
              {tagTitle}
            </Typography>
          )}
          {tags?.length && (
            <div className={`flex flex-wrap gap-2 ${justifyAlignment} pb-6`}>
              {links?.map(([link], idx) => (
                <AnalyticsLink
                  as={Button}
                  asPassthru={NextLink}
                  key={idx + link.name}
                  variant="outlined"
                  size="xs"
                  width="auto"
                  href={link.route}
                  target={link.isInternal ? undefined : '_blank'}
                  rel={link.isInternal ? undefined : 'noopener noreferrer'}
                  startDecorator={link.icon && <Icon icon={link.icon} color="text-primary" />}>
                  {link.name}
                </AnalyticsLink>
              ))}
            </div>
          )}
        </div>
        {!!providerHits?.length && (
          <div className="flex items-center gap-5">
            <button
              type="button"
              ref={(node) => setPrevEl(node)}
              className="flex lg:hidden items-center justify-center shrink-0 size-8 rounded-full bg-white border-2 border-gray-100 text-gray-700 disabled:text-gray-200 disabled:cursor-not-allowed">
              <Icon title="Previous Slide" icon="chevron-left" size="sm" />
            </button>
            <Swiper
              className="container"
              navigation={{
                prevEl,
                nextEl,
              }}
              onSlideChangeTransitionStart={() => {
                trackSnowplow({
                  event: { name: 'component_scroll', data: {} },
                  contexts: [
                    {
                      name: 'component',
                      data: {
                        component_text: 'FadPromoBlock',
                      },
                    },
                  ],
                })
              }}
              a11y={{
                enabled: true,
                firstSlideMessage: 'This is the first slide',
                lastSlideMessage: 'This is the last slide',
              }}
              keyboard={{
                enabled: true,
              }}
              modules={[A11y, Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 12,
                  slidesPerGroup: 1,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                  slidesPerGroup: 2,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 16,
                },
              }}
              centerInsufficientSlides>
              {providerHits?.map((providerPromo) => {
                return (
                  <SwiperSlide
                    key={providerPromo.id}
                    className="pb-2 pt-14 h-auto"
                    data-testid="provider-slides">
                    <ProviderPromoCard
                      promoProvider={providerPromo}
                      displayAppt={showAppointments!}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>

            <button
              type="button"
              ref={(node) => setNextEl(node)}
              className="flex lg:hidden items-center justify-center shrink-0 size-8 rounded-full bg-white border-2 border-gray-100 text-gray-700 disabled:text-gray-200 disabled:cursor-not-allowed">
              <Icon title="Next Slide" icon="chevron-right" size="sm" />
            </button>
          </div>
        )}
        {providerCount > 4 && (
          <div className={`flex ${justifyAlignment} pt-8`}>
            <AnalyticsLink as={Button} href={moreProvidersLink} data-testid="more-providers-button">
              {moreProvidersLabel}
            </AnalyticsLink>
          </div>
        )}
      </Container>
    </section>
  )
}

export default FadPromoBlock
